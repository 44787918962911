<template>
  <div>
    <v-container>
      <v-layout row wrap class="text-xs-center">
        <v-flex xs12 class="text-xs-center">
          <span class="headline">Test PayPal Checkout</span>
        </v-flex>
        <v-flex class="text-xs-center">
          <v-btn depressed large class="primary" @click="amount = 25, createPayment()">Pay $25</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Paypal from '@/services/PaypalService'

export default {
  name: 'Paypal',
  data () {
    return {
      amount: 0
    }
  },
  methods: {
    createPayment () {
      Paypal.createPayment({ amount: this.amount })
        .then(res => {
          window.location.href = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>

</style>